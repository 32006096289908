/* eslint-disable @typescript-eslint/interface-name-prefix */
import React from 'react';
import ModalEvaluationStoryInventoryComponent from './ModalEvaluationStoryInventoryComponent';

import routesPath from 'config/routesPath';
import { useFormik } from 'formik';
import { plateFormat } from 'helpers/plateFormat';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import InventoryServices from 'services/inventoryServices';
import * as Yup from 'yup';

export interface IEvaluation {
  evaluationStatus?: string;
  id: number;
  stock: boolean;
  isEvaluation: boolean;
  showroom: boolean;
  resale: boolean;
}

export interface ButtonConfig {
  condition: boolean;
  text: string;
  onClick: () => void;
  additionalStyles?: React.CSSProperties;
}

const ModalEvaluationStoryInventoryContainer = ({
  open,
  onClose,
  dealTypeSelect = null,
}) => {
  const {
    currentDealer: { id },
  } = useSelector(state => state?.dealerStore);

  const history = useHistory();

  const plateRegex = /[a-zA-z]{3}[0-9][0-9A-Z][0-9]{2}/;
  const vinRegex = /(?=.*\d|=.*[A-Z])(?=.*[A-Z])[A-Z0-9]{17}/;

  const EVALUATION_STATUS = {
    PENDING: 'PENDING',
    EVALUATED: 'EVALUATED',
    FINISHED: 'FINISHED',
  };

  const EVALUATION_MESSAGE = {
    PENDING:
      'Esse veículo já possui uma avaliação pendente no sistema. Aguarde a avaliação ser feita para seguir com a inclusão.',
    EVALUATED: 'Esse veículo já possui uma avaliação em andamento no sistema.',
    FINISHED:
      'O veículo possui Informações de cadastro pendentes, continue para complementar os dados.',
  };

  const DEAL_MESSAGE = {
    SHOWROOM: 'O veículo já pertence ao seu Showroom.',
    REPASSE: 'O veículo já pertence ao seu Repasse.',
  };

  const schema = Yup.object().shape(
    {
      plateOrVin: Yup.string()
        .when('plateOrVin', {
          is: value => {
            return plateRegex.test(value);
          },
          then: Yup.string().matches(plateRegex, 'Placa ou Chassi inválido.'),
          otherwise: Yup.string().matches(
            vinRegex,
            'Placa ou Chassi Inválido.'
          ),
        })
        .required('É necessário informar a placa ou Chassi'),
    },
    [['plateOrVin', 'plateOrVin']]
  );

  const [evaluationStatus, setEvaluation] = React.useState<IEvaluation>();
  const [evaluationSuccess, setEvaluationSuccess] = React.useState<boolean>(
    true
  );
  const [errorPlate, setErrorPlate] = React.useState();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const handleEvaluationStatus = async (plate: string) => {
    try {
      setIsLoading(true);
      const {
        success,
        data,
      }: any = await InventoryServices.getInventorySituation(id, plate);

      !success && setErrorPlate(data);

      if (success) {
        setEvaluation(data);
        setEvaluationSuccess(success);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  };

  const {
    values,
    errors,
    handleBlur,
    setFieldValue,
    resetForm,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: { plateOrVin: '' },
    validationSchema: schema,
    onSubmit: values => {
      handleEvaluationStatus(values.plateOrVin);
    },
  });

  const handlePushToScreen = () => {
    onClose();

    if (dealTypeSelect?.value == 'showroom') {
      history.push(
        `${routesPath.getRecursivePath(routesPath.dealForm)}?plate=${
          values?.plateOrVin
        }`
      );
      return;
    }

    if (dealTypeSelect?.value == 'resale') {
      history.push(
        `${routesPath.getRecursivePath(routesPath.resaleDealForm)}?plate=${
          values?.plateOrVin
        }`
      );
      return;
    }

    if (plateRegex.test(values.plateOrVin)) {
      history.push(
        `${routesPath.getRecursivePath(
          routesPath.mobiOneStoreInvetoryAddVehicle
        )}?plate=${values?.plateOrVin}`
      );
      return;
    }

    history.push(
      `${routesPath.getRecursivePath(
        routesPath.mobiOneStoreInvetoryAddVehicle
      )}`
    );
  };

  const handlePushToScreenEditVehicle = () => {
    if (dealTypeSelect?.value == 'showroom') {
      history.push(
        `${routesPath.getRecursivePath(routesPath.updateDeal, {
          id: evaluationStatus.id,
        })}`
      );
      return;
    }

    if (dealTypeSelect?.value == 'resale') {
      history.push(
        `${routesPath.getRecursivePath(routesPath.resaleDeaFormEdit, {
          dealId: evaluationStatus.id,
        })}`
      );
      return;
    }

    history.push(
      `${routesPath.getRecursivePath(
        routesPath.mobiOneStoreInvetoryEditVehicle,
        { id: evaluationStatus.id }
      )}`
    );
  };

  const onChange = (plateOrVin: string, value: string) => {
    setFieldValue('plateOrVin', plateFormat(value));
  };

  const onBlur = () => {
    handleBlur('plateOrVin');
  };

  const handleCloseModal = () => {
    onClose();
    resetForm();
    setEvaluation(null);
  };

  React.useEffect(() => {
    if (
      plateRegex.test(values.plateOrVin) ||
      vinRegex.test(values.plateOrVin)
    ) {
      handleEvaluationStatus(values.plateOrVin);
    }

    setEvaluation(null);
    setErrorPlate(null);
  }, [values.plateOrVin]);

  const canContinueToRegistration =
    !dealTypeSelect && !evaluationStatus?.isEvaluation;

  const buttons: ButtonConfig[] = [
    {
      condition: canContinueToRegistration,
      text: 'Continuar para cadastro',
      onClick: handlePushToScreen,
    },
    {
      condition:
        evaluationStatus?.evaluationStatus === EVALUATION_STATUS.PENDING,
      text: 'Fechar',
      onClick: onClose,
    },
    {
      condition:
        evaluationStatus?.isEvaluation &&
        evaluationStatus?.evaluationStatus === EVALUATION_STATUS.EVALUATED,
      text: 'Finalizar avaliação',
      onClick: () => history.push(routesPath.evaluations),
    },
    {
      condition:
        !dealTypeSelect &&
        evaluationStatus?.isEvaluation &&
        evaluationStatus?.evaluationStatus === EVALUATION_STATUS.FINISHED,
      text: 'Ir para cadastro',
      onClick: handlePushToScreenEditVehicle,
      additionalStyles: { padding: '10px 24px' },
    },
    {
      condition:
        !dealTypeSelect &&
        evaluationStatus?.stock &&
        !evaluationStatus?.isEvaluation &&
        evaluationStatus?.id &&
        evaluationStatus?.evaluationStatus !== EVALUATION_STATUS.EVALUATED &&
        values?.plateOrVin &&
        !errors?.plateOrVin,
      text: 'Ver detalhes do veículo',
      onClick: () =>
        history.push(
          `${routesPath.getRecursivePath(routesPath.mobiOneVehicleDetails, {
            id: evaluationStatus?.id,
          })}`
        ),
    },
    {
      condition:
        !isLoading &&
        evaluationStatus?.resale &&
        dealTypeSelect?.value === 'resale' &&
        !!values?.plateOrVin,
      text: 'Ver anúncio',
      onClick: handlePushToScreenEditVehicle,
    },
    {
      condition:
        !isLoading &&
        evaluationStatus?.showroom &&
        dealTypeSelect?.value === 'showroom' &&
        !!values?.plateOrVin,
      text: 'Ver anúncio',
      onClick: handlePushToScreenEditVehicle,
    },
    {
      condition:
        !isLoading &&
        evaluationStatus &&
        !evaluationStatus?.showroom &&
        dealTypeSelect?.value === 'showroom' &&
        evaluationStatus?.evaluationStatus !== EVALUATION_STATUS.PENDING &&
        evaluationStatus?.evaluationStatus !== EVALUATION_STATUS.EVALUATED,
      text: 'Continuar',
      onClick: handlePushToScreen,
    },
    {
      condition:
        !isLoading &&
        evaluationStatus &&
        !evaluationStatus?.resale &&
        dealTypeSelect?.value === 'resale' &&
        evaluationStatus?.evaluationStatus !== EVALUATION_STATUS.PENDING &&
        evaluationStatus?.evaluationStatus !== EVALUATION_STATUS.EVALUATED,
      text: 'Continuar',
      onClick: handlePushToScreen,
    },
  ];

  const shouldShowStockAlert = () => {
    return !!(
      !dealTypeSelect &&
      !isLoading &&
      !evaluationStatus?.isEvaluation &&
      evaluationStatus?.id &&
      evaluationStatus?.evaluationStatus !== EVALUATION_STATUS.EVALUATED &&
      values?.plateOrVin &&
      evaluationStatus?.stock &&
      !errors?.plateOrVin
    );
  };

  const shouldShowPendingAlert = () => {
    return !!(
      !isLoading &&
      evaluationStatus?.isEvaluation &&
      evaluationStatus?.evaluationStatus === EVALUATION_STATUS.PENDING &&
      values?.plateOrVin &&
      !errors?.plateOrVin
    );
  };

  const shouldShowEvaluatedAlert = () => {
    return !!(
      !isLoading &&
      evaluationStatus?.isEvaluation &&
      evaluationStatus?.evaluationStatus === EVALUATION_STATUS.EVALUATED &&
      values?.plateOrVin
    );
  };

  const shouldShowFinishedAlert = () => {
    return !!(
      !isLoading &&
      evaluationStatus?.isEvaluation &&
      evaluationStatus?.evaluationStatus === EVALUATION_STATUS.FINISHED &&
      values?.plateOrVin &&
      !errors?.plateOrVin &&
      !evaluationStatus?.showroom &&
      !evaluationStatus?.resale &&
      !dealTypeSelect?.value
    );
  };

  const shouldShowInvalidPlateOrVinAlert = () => {
    return !!(!isLoading && values?.plateOrVin && !evaluationSuccess);
  };

  const shouldShowErrorPlateAlert = () => {
    return !!(
      errorPlate &&
      values?.plateOrVin &&
      evaluationSuccess &&
      !errors?.plateOrVin
    );
  };

  const shouldShowShowroomAlert = () => {
    return !!(
      !isLoading &&
      evaluationStatus?.showroom &&
      dealTypeSelect?.value === 'showroom' &&
      values?.plateOrVin
    );
  };

  const shouldShowResaleAlert = () => {
    return !!(
      !isLoading &&
      evaluationStatus?.resale &&
      dealTypeSelect?.value === 'resale' &&
      values?.plateOrVin
    );
  };

  return (
    <ModalEvaluationStoryInventoryComponent
      open={open}
      onClose={handleCloseModal}
      isLoading={isLoading}
      values={values}
      touched={touched}
      errors={errors}
      onChange={onChange}
      handleSubmit={handleSubmit}
      onBlur={onBlur}
      EVALUATION_MESSAGE={EVALUATION_MESSAGE}
      DEAL_MESSAGE={DEAL_MESSAGE}
      dealTypeSelect={dealTypeSelect}
      errorPlate={errorPlate}
      buttons={buttons}
      shouldShowErrorPlateAlert={shouldShowErrorPlateAlert}
      shouldShowEvaluatedAlert={shouldShowEvaluatedAlert}
      shouldShowFinishedAlert={shouldShowFinishedAlert}
      shouldShowInvalidPlateOrVinAlert={shouldShowInvalidPlateOrVinAlert}
      shouldShowPendingAlert={shouldShowPendingAlert}
      shouldShowResaleAlert={shouldShowResaleAlert}
      shouldShowShowroomAlert={shouldShowShowroomAlert}
      shouldShowStockAlert={shouldShowStockAlert}
    />
  );
};

export default ModalEvaluationStoryInventoryContainer;
