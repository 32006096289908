import React from 'react';
import { format } from 'date-fns';
import { CardProps } from '../../types';
import * as S from './styles';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

const Card = ({
  quote,
  numberQuote,
  selectedQuote,
  handleOnClickInsuranceQuote,
  disableCard,
}: CardProps) => {
  const quantityQuote = numberQuote + 1;
  const name = `quote-${quote.quoteId}`;

  const handleCardClick = async () => {
    if (!disableCard) {
      handleOnClickInsuranceQuote(quote.id);
    }
  };

  return (
    <S.Container
      data-is-selected={selectedQuote === quote.id}
      data-is-waiting-results={disableCard}
      htmlFor={name}
      onClick={handleCardClick}
    >
      <S.Header>
        <div>
          <S.RadioWrapper>
            <FormControl component="fieldset" disabled={disableCard}>
              <RadioGroup name={name} value={selectedQuote}>
                <FormControlLabel
                  value={quote.id}
                  control={<Radio color="secondary" />}
                  label=""
                  disabled={disableCard}
                />
              </RadioGroup>
            </FormControl>
            <S.Title>Cotação {quantityQuote}</S.Title>
          </S.RadioWrapper>

          {quote?.quoteDate ? (
            <S.TextDate>
              {format(new Date(quote?.quoteDate), "dd/MM/yyyy 'às' HH:mm")}
            </S.TextDate>
          ) : null}
        </div>

        {quote?.quoteId && <S.Id>ID: {quote?.quoteId}</S.Id>}
      </S.Header>

      <S.StyledHr />

      <S.ContainerBody>
        {quote?.value && (
          <S.Line>
            <S.Text>Valor:</S.Text>
            <S.TextResponse>R$ {quote?.value}</S.TextResponse>
          </S.Line>
        )}
        {quote?.name && (
          <S.Line>
            <S.Text>Responsável:</S.Text>
            <S.TextResponse>{quote?.name}</S.TextResponse>
          </S.Line>
        )}
        {quote?.cpfCnpj && (
          <S.Line>
            <S.Text>CPF: </S.Text>
            <S.TextResponse>{quote?.cpfCnpj}</S.TextResponse>
          </S.Line>
        )}
        {quote?.financingInstitution && (
          <S.Line>
            <S.Text>Seguradora: </S.Text>
            <S.TextResponse>{quote?.financingInstitution}</S.TextResponse>
          </S.Line>
        )}
        {quote?.coverType && (
          <S.Line>
            <S.Text>Tipo da Cobertura: </S.Text>
            <S.TextResponse>{quote?.coverType}</S.TextResponse>
          </S.Line>
        )}
      </S.ContainerBody>
    </S.Container>
  );
};

export default Card;
