import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import EvaluationIcon from '@material-ui/icons/Stars';
import { ReactComponent as AgenteIcon } from 'assets/agente-icon.svg';
import { ReactComponent as AnotationIcon } from 'assets/anotationIcon.svg';
import { ReactComponent as CreditCardIcon } from 'assets/credit-card-icon.svg';
import { ReactComponent as InstitutionIcon } from 'assets/institution-icon.svg';
import { ReactComponent as SchedulingIcon } from 'assets/schedulingIcon.svg';
import { ReactComponent as SellerIcon } from 'assets/sellerIcon.svg';
import { ReactComponent as StoreIcon } from 'assets/store-icon.svg';
import { ReactComponent as VehicleIcon } from 'assets/vehicle-icon.svg';
import Lead360Icon from 'pages/chats/components/SideBarComponents/Lead360/assets/Lead360Icon';
import Tooltip from 'bit/mobiauto.web-ui-components.components.tooltip';

import DescriptionIcon from '@material-ui/icons/Description';
import SecurityIcon from '@material-ui/icons/Security';
import { ReactComponent as ClockIcon } from 'assets/clock-icon.svg';
import { ReactComponent as ServiceIcon } from 'assets/serviceIcon.svg';
import Wrapper from 'components/Wrapper';
import { useChatContext } from 'pages/chats/ChatsContext';
import proposalIsFinished from 'pages/chats/helpers/proposalIsFinished';
import Annotations from '../../../components/SideBarComponents/Annotations';
import BookVehicleInfos from '../../../components/SideBarComponents/BookVehicleInfos';
import ClientProfile from '../../../components/SideBarComponents/ClientProfile';
import DocumentsHistory from '../../../components/SideBarComponents/DocumentsHistory';
import FinancingSimulation from '../../../components/SideBarComponents/FinancingSimulation';
import Scheduling from '../../../components/SideBarComponents/Scheduling';
import ServiceHistory from '../../../components/SideBarComponents/ServiceHistory';
import StoreInfos from '../../../components/SideBarComponents/StoreInfos';
import Timeline from '../../../components/SideBarComponents/Timeline';
import VehicleInfos from '../../../components/SideBarComponents/VehicleInfos';
// import SearchAndRescueInfo from '../../../components/SideBarComponents/SearchAndRescueInfo';
import useDealerProducts, {
  ProductNameUnion,
} from 'hooks/useDealerHasProducts';
import { usePermissions } from 'hooks/usePermissions';
import AgentAccordion from 'pages/chats/components/SideBarComponents/AgentAccordion';
import MobiSmartConsultant from 'pages/chats/components/SideBarComponents/MobiSmartConsultant';
import Evaluation from 'pages/chats/components/SideBarComponents/Evaluation';
import InsurancePropostal from 'pages/chats/components/SideBarComponents/InsuranceProposal';

import ScrollView from 'components/ScrollView';
import DownPaymentSimulation from 'pages/chats/components/SideBarComponents/DownPaymentSimulation';
import Lead360 from 'pages/chats/components/SideBarComponents/Lead360';
import MCIIcon from 'pages/chats/components/SideBarComponents/MobiSmartConsultant/assets/MCIIcon';
import {
  Container,
  ShinyBadges,
  SideBarScrollView,
  ToolButton,
  ToolsBar,
  ToolsContent,
} from './styles';

const SECTIONS = {
  lead360: 'lead360',
  clientProfile: 'clientProfile',
  mobiSmartConsultant: 'mobiSmartConsultant',
  storeInfos: 'storeInfos',
  agentInfos: 'agentInfos',
  vehicleInfos: 'vehicleInfos',
  evaluation: 'evaluation',
  financinInfos: 'financinInfos',
  downPaymentInfos: 'downPaymentInfos',
  insurancePropostal: 'insurancePropostal',
  scheduling: 'scheduling',
  annotations: 'annotations',
  timeline: 'timeline',
  serviceHistory: 'serviceHistory',
  documentsHistory: 'documentsHistory',
};

const SideBarComponent = () => {
  const { hasProduct } = useDealerProducts();
  const { hasPermission } = usePermissions();
  const { currentDealer } = useSelector(state => state.dealerStore);
  const [currentToolSelected, setCurrentToolSelect] = useState<string | null>(
    hasProduct('MOBI_INTELIGENCIA_LEAD_360')
      ? SECTIONS['lead360']
      : SECTIONS['clientProfile']
  );

  const { currentProposal, otherStates }: any = useChatContext();

  const currentProposalSection = otherStates?.currentProposalSection;

  const handleChangeToolSelected = toolId => {
    setCurrentToolSelect(toolId === currentToolSelected ? null : toolId);
  };

  useEffect(() => {
    if (!currentProposalSection) return;

    handleChangeToolSelected(currentProposalSection);
  }, [currentProposalSection]);

  const getCurrentToolView = currentToolSelected => {
    switch (currentToolSelected) {
      case SECTIONS['lead360']:
        return (
          <ScrollView>
            <Lead360 />
          </ScrollView>
        );
      case SECTIONS['clientProfile']:
        return (
          <ScrollView>
            <ClientProfile
              closedProposal={proposalIsFinished(currentProposal)}
            />
          </ScrollView>
        );

      case SECTIONS['mobiSmartConsultant']:
        return <MobiSmartConsultant />;
      case SECTIONS['storeInfos']:
        return (
          <ScrollView>
            <StoreInfos closedProposal={proposalIsFinished(currentProposal)} />
          </ScrollView>
        );
      case SECTIONS['agentInfos']:
        return (
          <ScrollView>
            <AgentAccordion
              closedProposal={proposalIsFinished(currentProposal)}
              accordion={{
                callcenter: false,
                seller: true,
                searchAndRescueInfo: false,
              }}
            />
          </ScrollView>
        );
      case SECTIONS['vehicleInfos']:
        return (
          <ScrollView>
            <VehicleInfos
              closedProposal={proposalIsFinished(currentProposal)}
            />
            <BookVehicleInfos
              closedProposal={proposalIsFinished(currentProposal)}
            />
          </ScrollView>
        );
      case SECTIONS['evaluation']:
        return <Evaluation />;
      case SECTIONS['financinInfos']:
        return (
          <ScrollView>
            <FinancingSimulation />
          </ScrollView>
        );
      case SECTIONS['downPaymentInfos']:
        return <DownPaymentSimulation />;
      case SECTIONS['scheduling']:
        return (
          <ScrollView>
            <Scheduling closedProposal={proposalIsFinished(currentProposal)} />
          </ScrollView>
        );
      case SECTIONS['annotations']:
        return (
          <ScrollView>
            <Annotations />
          </ScrollView>
        );
      case SECTIONS['documentsHistory']:
        return (
          <ScrollView>
            <DocumentsHistory />
          </ScrollView>
        );
      case SECTIONS['timeline']:
        return (
          <ScrollView>
            <Timeline />
          </ScrollView>
        );
      case SECTIONS['serviceHistory']:
        return (
          <ScrollView>
            <ServiceHistory />
          </ScrollView>
        );
      case SECTIONS['insuranceProposal']:
        return (
          <ScrollView>
            <InsurancePropostal />
          </ScrollView>
        );
      default:
        return null;
    }
  };

  interface Tool {
    id: string;
    name: string;
    icon: JSX.Element;
    onClick?: () => void;
    product?: ProductNameUnion;
    externalProvider?: string;
    permission?: string;
  }

  const toolsList: Tool[] = [
    {
      id: SECTIONS['lead360'],
      name: 'Lead 360',
      icon: (
        <Wrapper position="relative" flex align="center" height={43}>
          <Lead360Icon />
          <Wrapper position="absolute" left={8}>
            <ShinyBadges>NOVO</ShinyBadges>
          </Wrapper>
        </Wrapper>
      ),
      onClick: (): void => {
        handleChangeToolSelected(SECTIONS['lead360']);
      },
      product: 'MOBI_INTELIGENCIA_LEAD_360',
    },
    {
      id: SECTIONS['clientProfile'],
      name: 'Cliente',
      icon: <SellerIcon style={{ width: 25, height: 20 }} />,
      onClick: (): void => {
        handleChangeToolSelected(SECTIONS['clientProfile']);
      },
    },
    {
      id: SECTIONS['mobiSmartConsultant'],
      name: 'Mobi Consultor Inteligente',
      icon: <MCIIcon />,
      onClick: (): void => {
        handleChangeToolSelected(SECTIONS['mobiSmartConsultant']);
      },
      product: 'MOBIGPT_FOR_LEADMANAGEMENT',
      permission: 'CONSULTANT',
    },
    {
      id: SECTIONS['storeInfos'],
      name: 'Loja',
      icon: <StoreIcon style={{ width: 20, height: 20 }} />,
      onClick: (): void => {
        handleChangeToolSelected(SECTIONS['storeInfos']);
      },
    },
    {
      id: SECTIONS['agentInfos'],
      name: 'Agente',
      icon: <AgenteIcon style={{ width: 20, height: 20 }} />,
      onClick: (): void => {
        handleChangeToolSelected(SECTIONS['agentInfos']);
      },
    },
    {
      id: SECTIONS['vehicleInfos'],
      name: 'Veículos',
      icon: <VehicleIcon style={{ width: 20, height: 20 }} />,
      onClick: (): void => {
        handleChangeToolSelected(SECTIONS['vehicleInfos']);
      },
    },
    {
      id: SECTIONS['evaluation'],
      name: 'Avaliação',
      icon: <EvaluationIcon style={{ width: 20, height: 20 }} />,
      onClick: (): void => {
        handleChangeToolSelected(SECTIONS['evaluation']);
      },
      product: 'EVALUATE',
    },
    {
      id: SECTIONS['financinInfos'],
      name: 'Financiamento',
      icon: <InstitutionIcon style={{ width: 20, height: 20 }} />,
      onClick: (): void => {
        handleChangeToolSelected(SECTIONS['financinInfos']);
      },
    },
    {
      id: SECTIONS['downPaymentInfos'],
      name: 'Simulação de entrada',
      icon: <CreditCardIcon style={{ width: 20, height: 20 }} />,
      onClick: (): void => {
        handleChangeToolSelected(SECTIONS['downPaymentInfos']);
      },
    },
    {
      id: SECTIONS['insuranceProposal'],
      name: 'Seguro',
      icon: <SecurityIcon style={{ width: 20, height: 20 }} />,
      onClick: (): void => {
        handleChangeToolSelected(SECTIONS['insuranceProposal']);
      },
      externalProvider: 'QUIVER',
    },
    {
      id: SECTIONS['scheduling'],
      name: 'Agendamento',
      icon: <SchedulingIcon style={{ width: 20, height: 20 }} />,
      onClick: (): void => {
        handleChangeToolSelected(SECTIONS['scheduling']);
      },
    },
    {
      id: SECTIONS['annotations'],
      name: 'Anotações',
      icon: <AnotationIcon style={{ width: 20, height: 20 }} />,
      onClick: (): void => {
        handleChangeToolSelected(SECTIONS['annotations']);
      },
    },
    {
      id: SECTIONS['documentsHistory'],
      name: 'Documentos',
      icon: <DescriptionIcon style={{ width: 20, height: 20 }} />,
      onClick: (): void => {
        handleChangeToolSelected(SECTIONS['documentsHistory']);
      },
    },
    {
      id: SECTIONS['timeline'],
      name: 'Histórico de atendimento',
      icon: <ServiceIcon style={{ width: 20, height: 20 }} />,
      onClick: (): void => {
        handleChangeToolSelected(SECTIONS['timeline']);
      },
    },
    {
      id: SECTIONS['serviceHistory'],
      name: 'Histórico da negociação',
      icon: <ClockIcon style={{ width: 20, height: 20 }} />,
      onClick: (): void => {
        handleChangeToolSelected(SECTIONS['serviceHistory']);
      },
    },
  ];

  return (
    <Container>
      <ToolsBar>
        <SideBarScrollView items={toolsList?.length}>
          {toolsList.map((tool, index) => {
            if (
              tool?.externalProvider &&
              !currentDealer?.externalDealerIdentification?.some(
                externalDealer =>
                  externalDealer.externalProvider === tool.externalProvider
              )
            ) {
              return null;
            }

            if (tool?.product && !hasProduct(tool.product)) {
              return null;
            }

            if (tool?.permission && !hasPermission(tool.permission)) {
              return null;
            }
            return (
              <Tooltip key={index} title={tool.name} placement="left">
                <ToolButton
                  isActive={currentToolSelected === tool.id}
                  onClick={tool.onClick}
                >
                  {tool.icon}
                </ToolButton>
              </Tooltip>
            );
          })}
        </SideBarScrollView>
      </ToolsBar>
      <ToolsContent currentToolSelected={currentToolSelected}>
        {getCurrentToolView(currentToolSelected)}
      </ToolsContent>
    </Container>
  );
};

export default memo(SideBarComponent);
