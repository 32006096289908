import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import AppsIcon from '@material-ui/icons/Apps';
import { CircularProgress, Tooltip } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import Progress from 'bit/mobiauto.web-ui-components.components.progress/Progress';
import Checkbox from 'bit/mobiauto.web-ui-components.components.checkbox/Checkbox';
import RadioButton from 'bit/mobiauto.web-ui-components.components.radio-button/RadioButton';
import isEmpty from 'helpers/isEmpty';
import { aggregationsTypes } from 'helpers/sop';
import DealCard from 'modules/financing/components/atoms/DealListCard';
import SearchInput from 'modules/financing/components/atoms/SearchInput';
import HypertextButton from 'modules/financing/components/atoms/HypertextButton';
import { localStore } from 'components/Storage';
import PopoverFilter from './components/PopoverFilter';
import { ReactComponent as EmptyVehicleListIcon } from 'assets/empty-vehicle-list-icon.svg';
import TableView from './components/TableView';
import { STOCK_KIND_TRANSLATE_ID } from 'modules/store-inventory/helpers/TranslateAggregations';
import {
  Container,
  FlexCenterContainer,
  FlexItem,
  FilterContainer,
  PageInfo,
  ListLoading,
  IconComponent,
  LoadMoreButton,
  EmptyListIconContainer,
  CheckboxFilterWrapper,
} from './styles';

//GRID_MODE_STATE_ON_LOCAL_STORAGE
const setGridModeToLocalStorage = currentGridMode => {
  localStorage.removeItem('currentGridMode');
  localStore.setItem(
    'currentGridMode',
    JSON.stringify(currentGridMode || false)
  );
};

const getGridModeFromLocalStorage = () => {
  const currentGridMode = localStore.getItem('currentGridMode');
  if (currentGridMode) {
    return JSON.parse(currentGridMode);
  }
};

const ListDealsComponent = props => {
  const [gridMode, setGridMode] = useState(
    getGridModeFromLocalStorage() || false
  );

  useEffect(() => {
    setGridModeToLocalStorage(gridMode);
  }, [gridMode]);

  const hasNextPage =
    props.dealsPage?.results?.length < props.dealsPage?.numResults;

  const stateSelectedFilters = props.sop[aggregationsTypes['STATE']?.sopKey]
    ? props.sop[aggregationsTypes['STATE']?.sopKey].split('.')[0]
    : null;
  const modelSelectedFilters = props.sop[aggregationsTypes['MODEL']?.sopKey]
    ? props.sop[aggregationsTypes['MODEL']?.sopKey].split('.')[0]
    : null;
  const stockKindSelectedFilters = props.sop[
    aggregationsTypes['STOCK_KIND']?.sopKey
  ]
    ? props.sop[aggregationsTypes['STOCK_KIND']?.sopKey].split('.')[0]
    : null;
  const colorsSelectedFilters = props.sop[
    aggregationsTypes['EXTERIOR_COLOR']?.sopKey
  ]
    ? props.sop[aggregationsTypes['EXTERIOR_COLOR']?.sopKey].split('.')[0]
    : null;
  const dealerSelectedFilters = props.sop[aggregationsTypes['DEALER']?.sopKey]
    ? props.sop[aggregationsTypes['DEALER']?.sopKey].split('.')[0]
    : null;

  return (
    <div>
      <FilterContainer>
        <Grid container spacing={1}>
          {/* ROW */}
          <Grid item xs={12}>
            <SearchInput
              value={props.query}
              onChange={event => props.handleChangeQuery(event.target.value)}
              placeholder="Busque por placa, nome, etc..."
              searchFunction={props.handleClickSearch}
              onKeyPress={props.handleKeyPress}
            />
          </Grid>
          {/* ROW */}
          {!isEmpty(props.aggregations) && (
            <>
              {props.aggregations?.STOCK_KIND?.elements?.length && (
                <Grid item style={{ flexGrow: 1 }}>
                  <PopoverFilter
                    filterType={'STOCK_KIND'}
                    selectedFilters={stockKindSelectedFilters}
                    filtersOptions={props.aggregations?.STOCK_KIND?.elements.map(
                      item => ({
                        ...item,
                        name: STOCK_KIND_TRANSLATE_ID[item.id]?.label,
                      })
                    )}
                    onChange={props.handleChangeFilter}
                  />
                </Grid>
              )}
              {props.aggregations?.DEALER?.elements?.length && (
                <Grid item style={{ flexGrow: 1 }}>
                  <PopoverFilter
                    filterType={'DEALER'}
                    selectedFilters={dealerSelectedFilters}
                    filtersOptions={props.aggregations?.DEALER?.elements}
                    onChange={props.handleChangeFilter}
                  />
                </Grid>
              )}
              {props.aggregations?.STATE?.elements?.length && (
                <Grid item style={{ flexGrow: 1 }}>
                  <PopoverFilter
                    filterType={'STATE'}
                    selectedFilters={stateSelectedFilters}
                    filtersOptions={props.aggregations?.STATE?.elements}
                    onChange={props.handleChangeFilter}
                  />
                </Grid>
              )}
              {props.aggregations?.MODEL?.elements?.length && (
                <Grid item style={{ flexGrow: 1 }}>
                  <PopoverFilter
                    filterType={'MODEL'}
                    selectedFilters={modelSelectedFilters}
                    filtersOptions={props.aggregations?.MODEL?.elements}
                    onChange={props.handleChangeFilter}
                  />
                </Grid>
              )}
              <Grid item style={{ flexGrow: 1 }}>
                <PopoverFilter
                  filterType={'EXTERIOR_COLOR'}
                  selectedFilters={colorsSelectedFilters}
                  filtersOptions={props.aggregations?.EXTERIOR_COLOR?.elements}
                  onChange={props.handleChangeFilter}
                />
              </Grid>
              <Grid item>
                <CheckboxFilterWrapper>
                  <Checkbox
                    checked={!!props.sop['avl_']}
                    onChange={() => {
                      props.handleChangeSop(
                        'avl_',
                        props.sop['avl_'] ? null : 't'
                      );
                      return;
                    }}
                  />
                  Apenas disponíveis
                </CheckboxFilterWrapper>
              </Grid>
            </>
          )}
          <Grid item>
            <HypertextButton onClick={props.handleClickCleanFilter}>
              Limpar Filtros
            </HypertextButton>
          </Grid>
          <Grid item>
            <div
              style={{ display: 'flex', alignItems: 'center', height: '100%' }}
            >
              <div
                style={{
                  justifyContent: 'center',
                  width: 32,
                  height: 32,
                  backgroundColor: '#fff',
                  border: '1px solid #e0e0e0',
                  borderRadius: 90,
                  cursor: 'pointer',
                  alignItems: 'center',
                  display: 'flex',
                }}
                onClick={() => setGridMode(!gridMode)}
              >
                {gridMode ? (
                  <FormatListBulletedIcon style={{ color: '#757575' }} />
                ) : (
                  <AppsIcon style={{ color: '#757575' }} />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </FilterContainer>
      {props.isLoading ? (
        <FlexCenterContainer>
          <Progress large />
        </FlexCenterContainer>
      ) : isEmpty(props?.dealsPage?.results) ? (
        <FlexCenterContainer column padding>
          <EmptyListIconContainer>
            <EmptyVehicleListIcon />
          </EmptyListIconContainer>
          Nenhum resultado encontrado.
        </FlexCenterContainer>
      ) : (
        <Container>
          {gridMode ? (
            <>
              {props.dealsPage.results.map((deal, index) => {
                return (
                  <Grid
                    key={`${deal.trimName}_${index}_${deal.id}`}
                    item
                    xs={12}
                  >
                    <FlexItem>
                      <DealCard
                        key={deal.id}
                        deal={deal}
                        canceled={!deal.available}
                        handleClick={() =>
                          !deal.available ? null : props.handleClickItem(deal)
                        }
                        currentSelected={props.selectedItemId}
                        selectComponent={
                          props.allAvaliable ? (
                            deal.available ? (
                              <RadioButton
                                checked={deal.id === props.selectedItemId}
                                onChange={() => props.handleClickItem(deal)}
                                name="vehicleRadio"
                                aria-label={deal.id}
                              />
                            ) : (
                              <Tooltip title={`Indisponível`} placement="top">
                                <IconComponent>
                                  <HighlightOffIcon />
                                </IconComponent>
                              </Tooltip>
                            )
                          ) : (
                            <RadioButton
                              checked={deal.id === props.selectedItemId}
                              onChange={() => props.handleClickItem(deal)}
                              name="vehicleRadio"
                              aria-label={deal.id}
                            />
                          )
                        }
                      />
                    </FlexItem>
                  </Grid>
                );
              })}
              <PageInfo>
                Exibindo {props.dealsPage.results.length} de{' '}
                {props.dealsPage.totalElements} veículos
              </PageInfo>
              {props.isLoadingMore ? (
                <ListLoading>
                  <CircularProgress />
                  <span>Carregando mais veículos...</span>
                </ListLoading>
              ) : (
                hasNextPage && (
                  <LoadMoreButton onClick={props.handleClickSearchMore}>
                    Carregar mais
                  </LoadMoreButton>
                )
              )}
            </>
          ) : (
            <TableView
              dealsList={props.dealsPage.results}
              handleClick={props.handleClickItem}
              currentSelected={props.selectedItemId}
              searchFunction={props.search}
              allAvaliable={props.allAvaliable}
              currentPage={props.currentPage}
              setCurrentPage={props.setCurrentPage}
              rowCount={props?.rowCount}
              pageSize={props.currentPage}
              setPageSize={props.setPageSize}
              loading={props.isLoadingTable}
              firstRenderingOfTheVehicleStep={
                props.firstRenderingOfTheVehicleStep
              }
              setFirstRenderingOfTheVehicleStep={
                props.setFirstRenderingOfTheVehicleStep
              }
            />
          )}
        </Container>
      )}
    </div>
  );
};

export default ListDealsComponent;
