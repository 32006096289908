import React from 'react';
import { ThemeButton } from './styles';
import { CircularProgress } from '@material-ui/core';
import Wrapper from 'components/Wrapper';

const ThemeButtonComponent = ({ children, ...props }): JSX.Element => {
  const { loading } = props;

  return (
    <ThemeButton {...props}>
      {loading ? (
        <CircularProgress style={{ color: 'white' }} size={15} />
      ) : (
        <Wrapper flex gap={8}>
          {children}
        </Wrapper>
      )}
    </ThemeButton>
  );
};

export default ThemeButtonComponent;
