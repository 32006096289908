import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import GenericDialog from 'components/GenericDialog';
import TextInput from 'modules/financing/components/atoms/TextField';
import React from 'react';
import { ButtonConfig } from './ModalEvaluationStoryInventoryContainer';
import AlertEvaluationStatus from './components/AlertEvaluationStatus';

interface Formik {
  plateOrVin: string | boolean;
}

interface EvaluationConstant {
  PENDING?: string;
  EVALUATED?: string;
  FINISHED?: string;
}

interface DealConstant {
  SHOWROOM?: string;
  REPASSE?: string;
}

type propTypes = {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  values: Formik;
  touched: Partial<Formik>;
  errors: Partial<Formik>;
  handleSubmit: () => void;
  onChange: (plate: string, value: string) => void;
  onBlur: (plate: string) => void;
  EVALUATION_MESSAGE: Partial<EvaluationConstant>;
  DEAL_MESSAGE: Partial<DealConstant>;
  dealTypeSelect: { label: string; value: string };
  errorPlate: string;
  buttons: ButtonConfig[];
  shouldShowStockAlert: () => boolean;
  shouldShowShowroomAlert: () => boolean;
  shouldShowPendingAlert: () => boolean;
  shouldShowEvaluatedAlert: () => boolean;
  shouldShowFinishedAlert: () => boolean;
  shouldShowInvalidPlateOrVinAlert: () => boolean;
  shouldShowErrorPlateAlert: () => boolean;
  shouldShowResaleAlert: () => boolean;
};

const Button = ({ children, onClick, style = {}, ...rest }) => (
  <Box
    component="button"
    color="#fff"
    bgcolor="#009A8A"
    border={0}
    borderRadius="4px"
    width="376px"
    height="42px"
    fontSize="14px"
    onClick={onClick}
    style={style}
    {...rest}
  >
    {children}
  </Box>
);

const renderAlert = (condition, message) =>
  condition && <AlertEvaluationStatus text={message} />;

const ModalEvaluationStoryInventoryComponent = ({
  open,
  onClose,
  isLoading,
  EVALUATION_MESSAGE,
  values,
  touched,
  errors,
  handleSubmit,
  onChange,
  onBlur,
  shouldShowErrorPlateAlert,
  shouldShowEvaluatedAlert,
  shouldShowFinishedAlert,
  shouldShowInvalidPlateOrVinAlert,
  shouldShowPendingAlert,
  shouldShowResaleAlert,
  shouldShowShowroomAlert,
  shouldShowStockAlert,
  DEAL_MESSAGE,
  dealTypeSelect,
  errorPlate,
  buttons,
}: propTypes) => {
  const renderButton = ({ condition, text, onClick, additionalStyles = {} }) =>
    condition && (
      <Button onClick={onClick} style={additionalStyles}>
        {text}
      </Button>
    );

  return (
    <GenericDialog
      title={
        dealTypeSelect ? `Novo ${dealTypeSelect?.label}` : 'Incluir Veículo'
      }
      open={open}
      onClose={onClose}
      DialogAction={() => (
        <Box
          component="div"
          width="100%"
          display="flex"
          justifyContent="flex-end"
        >
          {isLoading && <Skeleton width="376px" height="50px" />}

          {!isLoading &&
            buttons?.map((button, index) => (
              <React.Fragment key={index}>
                {renderButton(button)}
              </React.Fragment>
            ))}
        </Box>
      )}
    >
      <Box
        component="div"
        display="flex"
        flexDirection="column"
        gridGap="16px"
        ml={1}
      >
        <Box component="p" fontSize="16px" color="#000" m={0}>
          Informe a Placa
        </Box>
        <Box component="div" width="275px">
          <TextInput
            name="plateOrVin"
            label="Placa"
            placeholder="Placa"
            value={values.plateOrVin}
            error={touched.plateOrVin && Boolean(errors.plateOrVin)}
            helperText={Boolean(touched.plateOrVin) && errors.plateOrVin}
            onChange={e => {
              onChange('plateOrVin', e.target.value);
            }}
            onBlur={() => {
              onBlur('plateOrVin');
            }}
            InputProps={{
              maxLength: 7,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Clique para buscar"
                    onClick={() => handleSubmit()}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            mini
          />
        </Box>

        {renderAlert(
          shouldShowStockAlert(),
          'Esse veículo já pertence ao seu estoque.'
        )}
        {renderAlert(shouldShowPendingAlert(), EVALUATION_MESSAGE.PENDING)}
        {renderAlert(shouldShowEvaluatedAlert(), EVALUATION_MESSAGE.EVALUATED)}
        {renderAlert(shouldShowFinishedAlert(), EVALUATION_MESSAGE.FINISHED)}
        {renderAlert(
          shouldShowInvalidPlateOrVinAlert(),
          'Placa ou Chassi Inválido.'
        )}
        {renderAlert(shouldShowErrorPlateAlert(), errorPlate)}
        {renderAlert(shouldShowShowroomAlert(), DEAL_MESSAGE.SHOWROOM)}
        {renderAlert(shouldShowResaleAlert(), DEAL_MESSAGE.REPASSE)}
      </Box>
    </GenericDialog>
  );
};

export default ModalEvaluationStoryInventoryComponent;
